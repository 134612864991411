import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import styled from "styled-components"
import popupAlert from "../components/popup-alert"
import { db, colors } from "../components/siteVars"
import Layout from "../components/layout"
import { navigate, Link } from "gatsby"
import { setToken, getUser } from "../components/handle-auth"
import Input from "../components/input"
import Button from "../components/button"
import Contentbox from "../components/contentbox"
import Loader from "../components/loader"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/GlobalContextProvider"
import Axios from "axios"

const StyledLoginPageFunc = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: flex-start;
  form {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  h1 {
    margin-top: 0;
    &.h1-less-margin {
      margin-bottom: 0;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    form {
      width: 100%;
    }
  }
`

const LoginPageFuncLoader = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`

const LoginPageFunc = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (state.user.permissions) {
      navigate("/")
    }
  }, [])

  const verifyLogin = e => {
    setLoading(true)
    e.preventDefault()
    const user = {
      email: email,
      password: password,
    }
    axios
      .post(db.lambda + "users/login", user)
      .then(res => {
        if (res.data.authToken) {
          setLoading(false)
          dispatch({
            type: "SIGN_IN",
            token: res.data.authToken,
            user: res.data.user,
          })
          setToken(res.data.authToken)
          navigate("/planner")
        } else {
          setLoading(false)
          console.log("No token recieved")
        }
      })
      .catch(err => {
        setLoading(false)
        popupAlert(err.response.data)
      })
  }

  return (
    <>
      <LoginPageFuncLoader className={loading ? "active" : ""}>
        <Loader dualRing color={colors.mediumGrey} />
      </LoginPageFuncLoader>
      <Layout background>
        <StyledLoginPageFunc>
          <Contentbox background>
            <div className="login-page-left">
              <h1 className="h1-less-margin">Sign in</h1>
              <p>To use the teamgym planner you must first log in.</p>
              <form onSubmit={verifyLogin}>
                <Input
                  placeholder="Email"
                  label="Email"
                  type="text"
                  name="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                />
                <Input
                  placeholder="Password"
                  label="Password"
                  type="password"
                  value={password}
                  name="password"
                  onChange={e => {
                    setPassword(e.target.value)
                  }}
                />
                <Button type="submit">Sign in</Button>
              </form>
            </div>
          </Contentbox>
          <Contentbox background>
            <div className="login-page-right">
              <h1>Not a member?</h1>

              <Link to="/register">
                <Button color="green">Register now!</Button>
              </Link>
            </div>
          </Contentbox>
        </StyledLoginPageFunc>
      </Layout>
    </>
  )
}

export default LoginPageFunc
